<template>
    <canvas ref="mileageChart" id="mileageChart"></canvas>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
    name: "MileageOverview",
    setup() {
        
    },
    mounted() {
        this.generateChart()
    },
    methods: {
        generateChart() {
            Chart.defaults.color = "#fff";

            if(this.analyseItems.length <1) return
            let keys = Object.keys(this.analyseItems);
            let costsPerMile = [];
            let amountPerMile = [];
            let miles = []
            for(let key of keys) {
                costsPerMile.push(this.analyseItems[key].costsPerMile*100)
                amountPerMile.push(this.analyseItems[key].amountPerMile*100)
                miles.push(this.analyseItems[key].mileage)
            } 
            let ctx = this.$refs.mileageChart
            let data = {
                labels: keys,
                datasets: [
                    {
                    label: [
                        'Kilometerstand'
                    ],
                    data: miles,
                    borderColor:'#00FFFF',
                    backgroundColor: '#00FFFF'
                    }
                ]
            }

            const options = {
/*                     color: '#dddddd',
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: (ttItem) => {
                                    return `${ttItem.label}: ${ttItem.formattedValue }  €`;
                                } 
                            }
                        },
                        legend: {
                            align: 'start',
                            display: false,
                            position: 'bottom',
                            labels: {
                                textAlign: 'left',
                                usePointStyle: false,
                                font: {
                                    size: 14
                                }
                            }
                        }
                    },
                    layout: {
                        padding:5
                    } */
            }

            const CostChart = new Chart(ctx, {
                    type: 'bar',
                    data: data,
                    options: options
            })
            CostChart
        },
    },
    props: {
        analyseItems: Object
    }
}
</script>