<template>
    <div class="p-3">
        <h1>Changelog</h1>
        <h3>XX.11.2022 - v1.2.0</h3>
        <h5 class="text-success">Neu</h5>
        <ul>
            <li>Baujahr kann nun einem Fahrzeug hinzugefügt werden</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Für die Rücklagenempfehlung wird nun das Baujahr + Alter berücksichtig.</li>
        </ul>
        <h3>14.11.2022 - v1.1.2</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Beim Aufrufen einer neuen Transaktion wird die Transaktion des letzten Tankvorgangs angezeigt.</li>
            <li>Aufklappen der Transaktionskarte schlägt fehlt beim klicken auf das Datum oder den Betrag.</li>
        </ul>
        <hr>
        <h3>13.11.2022 - v1.1.1</h3>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Beim erstellen eines neuen Fahrzeugs kann dieses nicht bearbeitet werden.</li>
        </ul>
        <hr>
        <h3>13.11.2022 - v1.1.0</h3>
        <h5 class="text-success">Neu</h5>
        <ul>
            <li>Overlay Infobox für das Anzeigen wichtiger Informationen</li>
            <li>Filter und Suchfunktion bei Transaktionsliste</li>
            <li>Transaktionsbeschreibung</li>
            <li>Erstellung Changelog</li>
            <li>Hinweis und Weiterleitung wenn dem Fahrzeug noch keine Kraftsoffarten zugewiesen wurden</li>
            <li>Neue Kacheln auf der Startseite "Changelog" & "Einstellung"</li>
            <li>Analyseseite. Zugang über Dashboard => Kosten => Zur vollständigen Analyse</li>
            <li>Ein Hauptbild lässt sich vom Fahrzeug entfernen ohne ein neues Bild hochladen zu müssen</li>
            <li>Es wird ein Hinweis bzgl. zu großen Bildern angezeigt</li>
        </ul>
        <h5 class="text-info">Änderung</h5>
        <ul>
            <li>Das Fahrzeug wird nun automatisch gewechselt, sobald man ein Fahrzeug im Dashboard öffnet</li>
            <li>Transaktionskarte fährt auf und taucht nicht mehr plötzlich auf</li>
            <li>Im Tankformular kann nun mit Enter bzw. mit "," oder "." in das nächste Feld gesprungen werden</li>
            <li>Im Tankformular wird beim Import des Tankstellenpreises die Tankstellenadresse automatisch gespeichert</li>
            <li>Autopflege Button ist wurde klickbar und springt zum neuen Transaktionsformular</li>
            <li>Button "Neue Transaktion" in Transaktionsliste führt zum neuen Transaktionsformular</li>
            <li>Neues Fahrzeug Button wurde aus Fahrzeugliste entfernt. Neue Fahrzeuge können jetzt nur noch über die Kachel erstellt werden</li>
        </ul>
        <h5 class="text-warning">Fehlerbehebung</h5>
        <ul>
            <li>Absturz beim Import von Tankstellenpreisen</li>
            <li>Kraftstoffart wurde nicht automatisch vom Tankformular gespeichert</li>
        </ul>
        <h5 class="text-danger">Entfernt</h5>
        <ul>
            <li>Nichts</li>
        </ul>
        <hr>
    </div>

</template>

<script>
export default {
    name: "ChangeLog"
}
</script>

<style>
    h3 {
        text-align: center;
    }
</style>