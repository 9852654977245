import axios from "axios"
import qs from 'qs'
//import subscriptionItems from '../../assets/subscription/items.json';
import moment from "moment"

const state = {
    vehicleItems: [],
    transactionCategoryItems: [],
    fuelTypes: [],
    transactions: [],
    focusCarId: 0,
    analyse: {}
}

const singleYearRequest = async (startYear,endYear) => {
    let url = '/api/v1/vehicle/getTransactions/'+startYear+'/'+endYear;
    return axios.get(url).then((response) => {
       return response.data.content
    })
}


const mutations = {
    UPDATE_VEHICLES (state, payload) {
        payload.map((vehicle) => {
            vehicle.buyDate = moment(vehicle.buyDate).format('YYYY-MM-DD')
            vehicle.constructionYear = moment(vehicle.constructionYear).format('YYYY-MM-DD')
            vehicle.created =  moment(vehicle.created).format('YYYY-MM-DD HH:mm')
        }) 
        state.vehicleItems = payload
    },
    CLEAR_TRANSACTIONS(state) {
        state.transactions = []
    },
    ADD_TRANSACTIONS(state, payload) {
        payload.map((transaction) => {
            transaction.created =  moment(transaction.created).format('YYYY-MM-DD HH:mm')
            if(transaction.updated != null) {
                transaction.updated =  moment(transaction.updated).format('YYYY-MM-DD HH:mm')
            }
        })
        for(let trans of payload) {
            let index = state.transactions.findIndex(obj => obj.id === trans.id)
            if(index >= 0) {
                state.transactions[index] = trans;
            } else {
                state.transactions.push(trans)
            }

        }
    },
    UPDATE_TRANSACTION(state, payload) {
        let index = state.transactions.findIndex(obj => obj.id === payload.id)
        if(index >= 0) {
            state.transactions[index] = payload;
        } else {
            state.transactions.push(payload)
        }
    },
    REMOVE_TRANSACTION(state, payload) {
        let index = state.transactions.findIndex(obj => obj.id === payload.id)
        state.transactions.splice(index,1)
    },
    UPDATE_TRANSACTION_CATEGORY (state, payload) {
        state.transactionCategoryItems = payload
    },
    UPDATE_FUEL_TYPES (state, payload) {
        state.fuelTypes = payload
    },
    CHANGE_FOCUS_CAR(state, payload) {
        state.focusCarId = payload
    },
    UPDATE_ANALYSE(state, payload) {
        state.analyse = payload
    }
}

const actions = {
    getVehicleItems({commit}) {
        let url = '/api/v1/vehicle/getVehicles';
        return axios.get(url).then((response) => {
            commit('UPDATE_VEHICLES', response.data.content)
        })
    },
    async getTransactions({commit}, startYearPayload = 2019) {
        let startYear = startYearPayload
        let endYear = moment(new Date()).format('YYYY');
        let diff = 5;
        for(let i = startYear; i <= endYear; i = i + diff+1) {
            let data = await singleYearRequest(i, parseInt(i)+ parseInt(diff))
            commit('ADD_TRANSACTIONS', data)
        }

    },
    changeFocusCar({commit}, payload) {
        if(payload == undefined) {
            commit("CHANGE_FOCUS_CAR",window.localStorage.getItem('focusVehicle'))
        } else {
            if(state.focusCarId != payload) {
                let info = {
                    confirmText: "Alles klar!",
                    message: "Du bist in das Fahrzeug eingestiegen. Alle Änderungen werden nun auf <b>\"" + state.vehicleItems.find(obj => obj.id == payload).name + "\"</b> angewendet. Du kannst das Fahrzeug in der Garage wechseln"
                }
                this.dispatch('system/startNewInfo', info)
            }
            window.localStorage.setItem('focusVehicle', payload)
            commit("CHANGE_FOCUS_CAR",payload)
        }
    },
    // Zieht sich alles mögliche WIe die Transaktionskategorien als aber auch die Fuel Types
    getAllVehicleStuff({commit}, forceImport = false) {
        let storage = JSON.parse( window.localStorage.getItem('vehicleStuff') ); 
        if(storage == null || forceImport) {
            let url = '/api/v1/vehicle/getVehicleStuff';
            const request = axios.get(url)
            request.then((response) => {
                commit('UPDATE_TRANSACTION_CATEGORY', response.data.content.transactionCategory)
                commit('UPDATE_FUEL_TYPES', response.data.content.fuelTypes)
                window.localStorage.setItem('vehicleStuff',JSON.stringify( response.data.content));
            })
            return request;
        } else {
            commit('UPDATE_TRANSACTION_CATEGORY', storage.transactionCategory)
            commit('UPDATE_FUEL_TYPES',storage.fuelTypes)
        }
    },
    async addVehicle({commit}, payload) {
        payload.FuelTypesJson = JSON.stringify(payload.FuelTypes)
        let data =  qs.stringify(payload);
        
        let url = '/api/v1/vehicle/addVehicle';
        let request = axios.post(url, data)
        request.then((response) => {
            commit('UPDATE_VEHICLES', response.data.content)
        })
        return request;
    },
    async removeVehicle({commit}, payload) {
        let data =  qs.stringify(payload);
        let url = '/api/v1/vehicle/removeVehicle';
        let request = axios.post(url, data)
        request.then((response) => {
            commit('UPDATE_VEHICLES', response.data.content)
        })
        return request;
    },
    async saveVehicle({commit}, payload) {
        payload.vehicleTransactions=[]
        let data =  qs.stringify(payload);
        let url = '/api/v1/vehicle/saveVehicle/'+payload.id;
        let request = axios.post(url, data)
        request.then((response) => {
            commit('UPDATE_VEHICLES', response.data.content)
            this.dispatch('system/responseProcessing', response)
        })
        return request;
    },
    addTransaction({commit}, transaction) {
        if(transaction.id != undefined) {
            commit('UPDATE_TRANSACTION', transaction)
        }
        let data = qs.stringify(transaction);
        let url = '/api/v1/vehicle/addTransaction';
        let request = axios.post(url, data)
        request.then((response) => {
            if(response.status == 200 || response.status == 201) {
                this.dispatch('vehicle/getTransactions')
            }
            this.dispatch('system/responseProcessing', response)
        })
        return request;
    },
    removeTransaction({commit}, transaction) {
        commit('REMOVE_TRANSACTION', transaction)
        let data = qs.stringify(transaction);
        let url = '/api/v1/vehicle/removeTransaction/'+transaction.id;
        let request = axios.post(url, data)
        request.then((response) => {
            if(response.status == 200 || response.status == 201) {
                this.dispatch('vehicle/getTransactions')
            }
            this.dispatch('system/responseProcessing', response)
        })
        return request;
    },
    updateTransaction({commit}, transaction) {
        // Nur bei einer bekannten Transaktion wird diese direkt im Store hinterlegt. Wichtig da ansonsten ein falscher Eintrag erhalten ist
        if(transaction.id != undefined) {
            commit('UPDATE_TRANSACTION', transaction)
        }
        
        let idString = ""
        if(transaction.id != undefined) {
            idString = "/" + transaction.id
        }
        let data = qs.stringify(transaction);
        let url = '/api/v1/vehicle/updateTransaction'+idString;
        let request = axios.post(url, data)
        request.then((response) => {
            this.dispatch('vehicle/getTransactions')
            this.dispatch('system/responseProcessing', response)
        })
        return request;
    },
    uploadVehicleImage(commit, payload) {
        var formData = new FormData();
        for( var i = 0; i < payload.files.length; i++ ){
            let file = payload.files[i];
            formData.append('files[' + i + ']', file);
          }
        let url = '/api/v1/vehicle/uploadVehicleImage/'+payload.vehicle.id;
        let request = axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        request.catch((err) => {
            this.dispatch('system/responseProcessing', err.response)
        })
        return request; 
    },
    uploadTransactionImage(commit, payload) {
        var formData = new FormData();
        for( var i = 0; i < payload.files.length; i++ ){
            let file = payload.files[i];
            formData.append('files[' + i + ']', file);
          }
        let url = '/api/v1/vehicle/uploadTransactionImage/'+payload.transaction.id;
        let request = axios.post(url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        })
        request.catch((err) => {
            this.dispatch('system/responseProcessing', err.response)
        })
        return request; 
    },
    setMainImage(commit, payload) {
        let sendData = {
            vehicleId: payload.vehicle.id
        }
        if(payload.image == null) {
            sendData.imageId = null
        } else {
            sendData.imageId = payload.image.id
        }
        let data = qs.stringify(sendData);
        let url = '/api/v1/vehicle/setMainImage/';
        let request = axios.post(url, data)
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
        })
        return request
    },
    getAnalyse({commit}, vehicle= undefined) {
        let vehicleId = 0;
        if(vehicle == undefined) {
            vehicleId = state.focusCarId;
        } else {
            vehicleId = vehicle.id
        }
        let url = '/api/v1/vehicle/getAnalyses/' + vehicleId;
        return axios.get(url).then((response) => {
            commit('UPDATE_ANALYSE', response.data.content)
        })
    }


}

const getters = {
    getVehicleItems: state => state.vehicleItems,
    getTransactionCategories: state => state.transactionCategoryItems,
    getTransactions: state => state.transactions,
    getFuelTypes: state => state.fuelTypes,
    getFocusCar: state => state.focusCarId,
    getAnalyse: state => state.analyse
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};