<template>
    <div class="loadingPanel">
        <div class="loadingIcon">
            <div class="txBtn txBtn-primary txBtn-primary-vision">
                <i class="fa-solid fa-spinner fa-4x fa-spin"></i>
            </div>
            <button class="mt-2 txBtn txBtn-primary txBtn-primary-vision">
                Daten werden geladen ...
            </button>
        </div>
    </div>
</template>

<script>
/* import { mapGetters } from 'vuex' */

export default {
    data() {
        return {

        }
    },
    computed: {
    },
    methods: {
    },
    components: {
    
    }

}
</script>

<style lang="scss" scoped>
    .loadingPanel {
        background-color: rgba($color: #000000, $alpha: 0.8);
        position: absolute;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
    }

    .loadingIcon {
        text-align: center;
        vertical-align: middle;
        margin-top: 50px;
        }
</style>