<template>
    <td>
        {{user.id}}
    </td>
    <td v-if="true">
        {{ user.name }}
    </td>
    <td>
        {{ user.email }}
    </td>
    <td>
        <span :key="index" v-for="(role, index) of user.roles">
        {{ role }}<br>
        </span>
    </td>
    <td>
        <button @click="goToProfile" class="txBtn txBtn-primary"><i class="fa-solid fa-pen-to-square"></i></button>
    </td>
</template>

<script>

export default {
    data() {
        return {
        }
    },
    created() {
    },
    methods: {
        goToProfile() {
            this.$router.push(`/profile/${this.user.id}`)
        }
    },
    computed: {
    },
    props: {
        user: Object
    }
}
</script>