<template>
        <div v-show="active" @click="setDisable" class="dgModal">
            <div @click.stop class="card dgModal-content">
                <div class="card-header text-center">
                    <h3>Neuer User</h3>
                </div>
                <div class="card-body text-black">
                    <div class="mb-2">
                            <label for="email" class="form-label">Email Adresse</label>
                            <input v-model="editUser.email" class="form-control" type="text" />
                            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>
                    <div class="mb-2">
                            <label for="firstname" class="form-label">Vorname</label>
                            <input v-model='editUser.firstname' class="form-control" type="text" />
                            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>
                    <div class="mb-2">
                            <label for="firstname" class="form-label">Nachname</label>
                            <input v-model='editUser.lastname' class="form-control" type="text" autocomplete="off"  />
                            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>
                    <div class="mb-2">
                            <label for="firstname" class="form-label">Passwort</label>
                            <input v-model='editUser.password' class="form-control" type="password" autocomplete="new-password" />
                            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                    </div>  
                    <button @click="addNewUser" for="save" class="form-control mt-5 btn btn-success">Speichern</button>
                </div>
            </div>
        </div>
</template>

<script>

export default {
    data() {
        return {
            editUser: {}
        }
    },
    created() {
    },
    methods: {
        setDisable() {
            this.$emit('setDisable');
        },
        addNewUser() {
            this.$store.dispatch('user/addNewUser',this.editUser).then((response) => {
                console.log(response)
                if(response.status == 201) {
                    this.$store.dispatch('user/getUserList');
                    this.setDisable();
                    this.reset();
                }
            })
        },
        reset() {
            this.editUser = {};
        }
    },
    computed: {
    },
    props: {
        active: Boolean
    }
}
</script>