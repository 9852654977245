<template>
    <div v-show="active" @click="setDisable" class="dgModal">
        <div @click.stop class="card dgModal-content">
            <div class="card-header text-center">
                <h3>Neues Fahrzeug</h3>
            </div>
            <div class="card-body text-black">
                <div class="mb-2">
                        <label for="email" class="form-label">Name</label>
                        <input v-model="editVehicle.name" class="form-control" type="text" />
                        <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                </div>
                <div class="mb-2">
                        <label for="firstname" class="form-label">Kennzeichen</label>
                        <input v-model='editVehicle.licensePlate' class="form-control" type="text" />
                        <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
                </div>
                <button @click="addNew" for="save" class="form-control mt-5 btn btn-success">Speichern</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
data() {
    return {
        editVehicle: {}
    }
},
created() {
},
methods: {
    setDisable() {
        this.$emit('setDisable');
    },
    addNew() {
        this.$store.dispatch('vehicle/addVehicle',this.editVehicle).then((response) => {
            if(response.status == 201) {
                this.setDisable();
                this.reset();
            }
        })
    },
    reset() {
        this.editVehicle = {};
    }
},
computed: {
},
props: {
    active: Boolean
}
}
</script>