/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/index.scss'

import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'


if ('serviceWorker' in navigator) {

    window.addEventListener('load', () => {

      navigator.serviceWorker.register('/build/service-worker.js').then(registration => {
        console.log('SW registered: ', registration);
      })
      .catch(registrationError => {
 
        console.log('SW registration failed: ', registrationError);
 
      });
 
    });
 
  }
const CreatedApp = createApp(App)
CreatedApp.use(store)
CreatedApp.use(router)
CreatedApp.config.globalProperties.__VUE_PROD_DEVTOOLS__ = false

CreatedApp.mount('#app')
