import axios from "axios"
import qs from 'qs'
//import subscriptionItems from '../../assets/subscription/items.json';


const state = {
    user: {
        roles: []
    },
    userSelect: {},
    userList: []
}


const mutations = {
    UPDATE_USERS (state, payload) {
        state.userList = payload
    },
    UPDATE_USER ( state, payload) {
        state.user = payload
    },
    UPDATE_USER_SELECT ( state, payload) {
        state.userSelect = payload
    }
}

const actions = {
    async getUser({commit}) {
        let url = 'https://dev.grabasch.com/api/v1/getUser';
        return axios.get(url).then((response) => {
            commit('UPDATE_USER', response.data.content)
        })
    },
    async getUserSelect({commit}, payload) {
        let url = 'https://dev.grabasch.com/api/v1/getUser';
        if(payload != undefined) {
            url += '/' + payload
        }
        return axios.get(url).then((response) => {
            commit('UPDATE_USER_SELECT', response.data.content)
        })
    },
    async saveUser(commit, payload) {
        let data =  qs.stringify(payload);
        let request = axios.post('https://dev.grabasch.com/api/v1/saveUser/'+payload.id,data);
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
        }) 
        return request;    },
    async getUserList({commit}) {
        return axios.get('https://dev.grabasch.com/api/v1/getUserlist').then((response) => {
            commit('UPDATE_USERS', response.data.content);
        })
    },
    async addNewUser({commit}, payload) {
        let data =  qs.stringify(payload);
        let request = axios.post('https://dev.grabasch.com/api/v1/addNewUser', data);
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
            if(response.status == 201) {
                commit('UPDATE_USERS', response.data.content);
            }
        })
        return request;
    },
    async setNewPassword({commit}, payload) {
        let data =  qs.stringify(payload.password);
        let request = axios.post('https://dev.grabasch.com/api/v1/setNewPassword/'+payload.userId, data);
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
            commit('UPDATE_USERS', response.data.content);
        })
        return request;
    },    
    async removeUser({commit}, userId) {
        let request = axios.post('https://dev.grabasch.com/api/v1/removeUser/'+userId);
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
            commit('UPDATE_USERS', response.data.content);
        })
        return request;
    }
}

const getters = {
    getUserList: state => state.userList,
    getUser: state => state.user,
    getUserSelect: state => state.userSelect,
    isAdmin (state) {
        if(state.user.roles.find(obj => obj == 'ROLE_ADMIN') !== undefined) {

            return true
        } else {
            return false
        }
    }
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};