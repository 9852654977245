<template>
    <div @click="openMenu" class="txBtn pointer txBtn-primary">
        <i class="fa-solid fa-ellipsis fa-lg"></i>
    </div>
    <div @mouseleave="closeMenu" v-show="isVisable" :id="'itemNavi'+vehicle.id"  class="itemNavi text-dark" >
        <div class="">
            <div class="card-header">
                {{vehicle.name}}
            </div>
            <div @click="goToDashboard" class="card-footer">
                Dashboard
            </div>   
            <div @click="goToVehicleEdit" class="card-footer">
                Bearbeiten
            </div>       
            <div @click="goToTransaction" class="card-footer">
                Alle Transaktionen
            </div> 
            <div @click="goToNewTransaction" class="card-footer">
                Neue Transaktion
            </div>
            <div @click="remove" class="card-footer text-danger">
                Löschen
            </div>
        </div>
    </div>
    <div @click="closeMenu" v-show="isVisable" class="bgForClickEvent">
    </div>
</template>

<script>
export default {
    data() {
        return {
            parentDiv: null,
            isVisable: false,
            widthPos: 0
        }
    },
    mounted() { 
        
    },
    methods: {
        openMenu(event) {
           
            this.isVisable = !this.isVisable;
            let menuElement = document.getElementById('itemNavi'+this.vehicle.id);

            let windowWidth = document.documentElement.clientWidth;
            let leftPos = event.layerX
            let yStyle  = "top:"+ event.layerY+"px;";

            let stylePosition = "position: absolute;";
            if(windowWidth < (event.pageX+300)) {
                leftPos -=  300;
            }

            // Wenn es ein kleiner Monitor ist wird automatisch die Eingabe zentriert
            this.widthPos = windowWidth
            if(windowWidth < 400) {
               // let borderWidth = windowWidth-300;
                leftPos = 0//borderWidth/2;
                stylePosition = "position: fixed;"
                yStyle = "top:"  + 0 +   "px;width:"+windowWidth+"px;";
            }
    


            menuElement.style = stylePosition + yStyle+'left:'+ (leftPos)+'px'
        },
        closeMenu() {
            this.isVisable = false;
        },
        remove() {
            const dialog = {
                
                message: 'Bist du sicher, dass du ' + this.vehicle.name + " löschen möchtest",
                confirmText: 'Ja bitte löschen',
                cancelText: "Abbruch",

            }
            dialog.confirmFunction = () => {
                this.$store.dispatch('vehicle/removeVehicle', this.vehicle ).then((response) => {
                    if(response.status == 201) {
                     // 
                    }
                })
            }
            this.$store.dispatch('system/startNewConfirm', dialog)
            this.closeMenu();
        },
        goToDashboard() {
            this.$store.dispatch('vehicle/changeFocusCar', this.vehicle.id).then(() => {
                this.$router.push({name: 'vehicleDashboard' , params: { vehicleId: this.vehicle.id}})         
            })
        },
        goToVehicleEdit() {
            this.$router.push({name: 'vehicleEdit' , params: { vehicleId: this.vehicle.id}})
        },
        goToTransaction() {
            this.$router.push({name: 'vehicleTransaction' , params: { vehicleId: this.vehicle.id}})
        },
        goToNewTransaction() {
            this.$store.dispatch('vehicle/changeFocusCar', this.vehicle.id).then(() => {
                this.$router.push({name: 'transactionForm'})
            }) 
        }
    },
    props: {
        vehicle: Object
    }
}
</script>

<style lang="scss" scoped>
    .itemNavi {
        position: absolute;
        background-color: white;
        width: 300px;
        z-index: 200;
        border-radius: 3px;
    }

    .card-footer:hover {
        background: rgba($color: #000000, $alpha: 0.2);
        cursor: pointer;
    }

    .bgForClickEvent {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 100;
    }

</style>

