<template>
  <h1>Einstellung</h1>



    <div v-if="user.mailNotificationSetting && user.backupSetting.vehicle" class="p-2">
      <h3>Benachrichtigung</h3>
      <div class="form-check">
          <input class="form-check-input" @change="enableSave" type="checkbox" v-model="user.mailNotificationSetting.developingNews" id="developingNewsCheckbox">
          <label class="form-check-label" for="developingNewsCheckbox">
            Informiere mich über aktuelle Entwicklungsneuigkeiten.
          </label>
      </div>    
      <h3>Backup</h3>
      <p>Möchtest du ein persönliches Backup deiner Daten per Email zugesendet bekommen?</p>
      <button class="btn btn-outline-secondary">Jetzt erstellen</button>
      <button v-if="!user.backupSetting.vehicle.enable" @click="setActivateVehicleBackup(true)" class="btn btn-outline-primary ms-1">Autobackup aktivieren</button>
      <button v-if="user.backupSetting.vehicle.enable"  @click="setActivateVehicleBackup(false)" class="btn btn-outline-primary ms-1">Autobackup deaktivieren</button>
      <div v-if="user.backupSetting.vehicle.enable" class="input-group mt-1">
        <span class="input-group-text text-dark">Backupintervall</span>
        <input class="form-control" v-model="user.backupSetting.vehicle.interval" />
        <span class="input-group-text text-dark">Tage</span>
      </div>

      <button v-if="showSaveButton" @click="save" class="btn btn-success w-100 mt-5">Speichern</button>
    </div>
    <div class="p-2">
      <a href="/logout">
        <button  class="btn w-100 btn-danger"><b>Ausloggen</b></button>
      </a>
    </div>

</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "SettingsWindow",
    data() {
      return {
        showSaveButton: false
      }
    },
    created() {
      this.$store.dispatch('user/getUser').then(() =>  {
        this.init();
      })
      
    },
    methods: {
      init() {
        if(this.user.mailNotificationSetting.developingNews == undefined) {
          this.user.mailNotificationSetting.developingNews = false;
        }
        if(this.user.backupSetting.vehicle == undefined) {
          this.user.backupSetting.vehicle = {}
          this.user.backupSetting.vehicle.interval = 30
          this.user.backupSetting.vehicle.enable = false
          this.user.backupSetting.vehicle.lastBackup = "Never"
        }
        if(this.user.backupSetting.vehicle.enable == "true") {
          this.user.backupSetting.vehicle.enable = true;
        } else {
          this.user.backupSetting.vehicle.enable = false
        }
        console.log(this.user.backupSetting.vehicle.enable)
      },
      enableSave() {
        this.showSaveButton = true
      },
      setActivateVehicleBackup(value) {
        this.enableSave();
        this.user.backupSetting.vehicle.enable = value;
      },
      save() {
        this.showSaveButton = false
        this.$store.dispatch('user/saveUser', this.user);
      }
    },
    computed: {
      ...mapGetters({
        user: 'user/getUser'
      })
    }
}
</script>

<style>

</style>