import axios from "axios"

//import subscriptionItems from '../../assets/subscription/items.json';


const state = {
    stations: []
}


const mutations = {
    UPDATE_STATIONS(state, payload) {
        state.stations = payload
    },
    CLEAR_STATIONS(state) {
        state.stations = []
    }
}

const actions = {
    getFuelStation({commit}, payload) {
        commit('CLEAR_STATIONS')
        let url = "https://creativecommons.tankerkoenig.de/json/list.php?lat="+payload.lat+"&lng="+payload.long+"&rad="+payload.distance+"&sort=dist&type=all&apikey=a95b3b7f-d87d-bc1b-b02c-992504b7a537"
        const request = axios.get(url)
        request.then((res) => {
            commit('UPDATE_STATIONS', res.data.stations)
        })
        request.catch(() => {
            let flash = {
                type: "danger",
                message: "Es gab einen Fehler bei der Übertagung an Tankkönig! Versuche es später nochmal"
            }
            this.dispatch('system/addFlashMessage', flash)
        })
    }

}

const getters = {
    getStations: state => state.stations
}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};