<template>
    <div v-if="dialog.enabled" class="backgroundDialog fixed-top ">
        <div  id="ConfirmDialog" class=" border border-primary m-3 rounded p-1 bg-dark">
            <div class="text-center">
                <div class="m-auto" v-html="dialog.message">
                </div>
                <div class="mt-2">
                    <button @click="callFunction" class="btn btn-outline-secondary">{{dialog.confirmText}}</button>
                </div>
            </div>
        </div>
    </div>
</template>    

<script>
import { mapGetters } from 'vuex';
    
export default {
    data() {
        return {
        }
    },
    created() {
    },
    props: {
    },
    methods: {
        cancel() {
            this.$store.dispatch('system/disableInfo', this.dialog);
        },
        callFunction() {
            if(this.dialog.confirmFunction != undefined) {
                this.dialog.confirmFunction()
            }
            this.cancel();
        }
    },
    computed: {
        ...mapGetters({
            dialog: 'system/getInfoDialog'
        })
    }
}
</script>

<style scoped lang="scss">
.backgroundDialog {
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    width: 100%;
    z-index: 2000;
}

#ConfirmDialog {

}
</style>