<template>
    <div @click="removeItem" class="alert" :class="'alert-'+element.type" v-html="element.message">
    </div>
</template>

<script>

    
export default {
    data() {
        return {
        }
    },
    created() {
        let aliveTime = 10000;
        if(this.element.aliveTime > 0) {
            aliveTime = this.element.aliveTime;
        }
        setTimeout(this.removeItem, aliveTime);
    },
    methods: {
        removeItem() {
            this.$emit('remove', this.element)
        }
    },
    props: {
        element: Object
    }

}
</script>