<template>
    <h1>Passwort ändern</h1>
    <form   autocomplete="off" class="formStyle">
        <div v-if="!isAdmin" class="mb-2">
            <label for="oldPassword" class="form-label">Alte Passwort</label>
            <input v-model="passwords.old" class="form-control" type="password"  />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="newPassword" class="form-label">Neues Passwort</label>
            <input v-model="passwords.new" class="form-control" type="password"  autocomplete="off" />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <div class="mb-2">
            <label for="repeatPassword" class="form-label">Passwort wiederholen</label>
            <input v-model="passwords.repeat" class="form-control" type="password"  autocomplete="off"  />
            <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
        </div>
        <button for="save" @click="goToProfile" class="form-control mt-5 btn btn-outline-warning">Abbrechen</button>
        <button for="save" @click="savePassword" class="form-control mt-1 btn btn-success"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern</button>
    </form>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    data() {
        return {
            passwords: {},
            isSaving: false
        }   
    },
    created() {
        this.$store.dispatch('user/getUser')
    },
    methods: {
        savePassword() {
            this.isSaving = true;
            this.$store.dispatch('user/setNewPassword', {password: this.passwords, userId: this.$route.params.userId }).then(() => {
                setTimeout(() => {
                    this.isSaving = false
                }, 100)
            }) ;
        },
        goToProfile() {
            this.$router.push({name: 'profile', params: {userId: this.userSelect.id}})
        }
    },
    computed: {
        ...mapGetters({
            userSelect: 'user/getUserSelect',
            user: 'user/getUser',
            isAdmin: 'user/isAdmin'
        })
    }

}
</script>