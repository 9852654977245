<template>
    <h1>Userliste</h1>

    <div class="d-flex justify-content-end">
        <button @click="showNewUserModal=true" class="txBtn txBtn-primary">User Hinzufügen</button>
    </div>


    <div class="border-top border-primary mt-3">
        <table class="table table-dark table-striped">
            <tbody>
            <tr class="" :key="user.id" v-for="(user) of userItems">
                <UserItem :user="user" />
            </tr>
            </tbody>
        </table>
    </div>


    <NewUser :active="showNewUserModal" @setDisable="showNewUserModal=false" />
</template>

<script>
import { mapGetters } from 'vuex'
import UserItem from '../../components/user/UserItem.vue';
import NewUser from '../../components/user/NewUser.vue';


export default {
    data() {
        return {
            showNewUserModal: false
        };
    },
    created() {
        this.$store.dispatch("user/getUserList");
    },
    methods: {},
    computed: {
        ...mapGetters({
            userItems: "user/getUserList"
        })
    },
    components: { UserItem, NewUser }
}
</script>