<template>
    <div  class="m-0 p-0 container-fluid">
        <div class="headerContainer">     
            <div class="overlayContent"></div>     
            <div class="headerImage">
                 <img class="img-fluid rounded" :src="imageLink" />
            </div>   
            <div class="headerText">
                <h1>{{headerText}}</h1>
            </div>
            
        </div>
    </div> 
</template>

<script>

    
export default {
    props: {
        headerText: String,
        imageLink: String
    },
    computed: {
        getImage() {
            return require(this.imageLink)
        }
    }

}
</script>

<style lang="scss" scoped>
    .headerContainer {
        position: relative;
        text-align: center;
        color: white;
        max-height:120px;
        overflow: hidden;
    }
    .headerText {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .overlayContent {
        position: absolute;
        background-color: rgba(0,0,0,0.5);
        height: 100%;
        width: 100%;
    }

</style>