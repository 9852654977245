<template>
    <h3 v-if="isNewTrans" class="text-center">Neue Transaktion</h3>
    <h3 v-if="!isNewTrans" class="text-center">Transaktion bearbeiten</h3>
    <div v-if="vehicle" class="p-1">
        <vehicle-item :vehicle="vehicle" />
    </div>
    <div v-if="transactionEdit">
        <div class="w-100" v-if="!transactionEdit.category">
            <p class="text-center p-0">
                Wähle ein Transaktionskategorie
            </p>
            <div class="card bg-black mt-0 m-1" :key="trans.id" v-for="(trans) of transCategories">
                <button class=" btn btn-outline-primary" @click="selectCategory(trans)">
                    <i :class="trans.faIcon" class="float-start mt-1"></i>  {{trans.name}}
                </button>
            </div>
        </div>
        <div v-if="transactionEdit.category">
            <div class="text-center p-2 pointer" @click="selectCategory(null)">
                <h5>
                    <i :class="transactionEdit.category.faIcon" class="float-start mt-1"></i>  
                    {{transactionEdit.category.name}}
                    <span class="text-danger float-end mt-1">
                        <i class="fa-solid fa-xmark fa-lg"></i>
                    </span>
                </h5>
            </div>
            <div  @click="focusKmInput" class="input-group p-1">
                <span class="input-group-text text-dark minWidth">KM-Stand</span>
                <input type="number" v-model="transactionEdit.mileage" ref="kmInput" class="form-control" />
            </div>
            <div v-if="transactionEdit.category.id==1" class="input-group p-1">
                <span class="input-group-text text-dark minWidth">Menge</span>
                <input type="number" v-model="transactionEdit.amount" class="form-control" />
                <span class="input-group-text text-dark">l</span>
            </div>
            <div v-if="transactionEdit.category.id==1" class="input-group p-1">
                <span class="input-group-text text-dark minWidth">Kraftstoff</span>
                <select v-if="transactionEdit.category.id === 1"  class="form-select" v-model="transactionEdit.FuelType">
                    <option :key="type.id" :value="type" v-for="(type) of vehicle.FuelTypes">
                        {{type.name}}
                    </option>
                </select>
            </div>
            <div class="input-group p-1">
                <span class="input-group-text text-dark minWidth">Kosten</span>
                <input type="number" v-model="transactionEdit.costs" class="form-control" />
                <span class="input-group-text text-dark">€</span>
            </div>
            <hr>
            <div class="input-group p-1">
                <span class="input-group-text text-dark minWidth">Bezeichnung</span>
                <input type="text" v-model="transactionEdit.name" class="form-control" />
            </div>
            <div class="m-1">
                <div class="text-light">Beschreibung / Details</div>
                <textarea class="rounded bg-dark text-white w-100" v-model="transactionEdit.description">
                </textarea>
            </div>
            <div v-if="!isNewTrans" class="col-12 col-md-6">
                <div class="mb-2 ">
                    <label for="dateCreated" class="form-label">Bilder & Dateien</label>
                    <div>          
                        <label class="custom-file-upload text-center">
                        <input ref="file" type="file" multiple  @change="prepareFileUpload( $event )" />
                            <div class="m-4">
                                <div  v-if="!fileUploadEvent" class="inline-block align-middle">
                                <i class="fa-solid fa-images fa-2x"></i> 
                                    <div>
                                        Ziehe Fotos in das Feld oder wähle welche aus.
                                    </div>
                                </div>
                                <div class="m-4" v-if="fileUploadEvent" >
                                    <div class="mb-2">
                                    <span  v-if="fileUploadEvent">{{fileUploadEvent.length}}x Dateien oder Bilder</span>
                                    </div>
                                    <button @click.stop v-if="!uploadProcessIsRunning" @click="uploadFiles" class="btn btn-primary">Hochladen</button>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4" :key="image.id" v-for="image in transactionEdit.allImages">
                        <image-card :image="image" @removedFile="removedFile" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-if="allowedToSave" class="mt-1">
                <button @click="save(false)" class="btn btn-success w-100"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern</button>
                <button v-if="isNewTrans" @click="save(true)" class="btn btn-success w-100 mt-1"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern & Dokumente hochladen</button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import VehicleItem from "../../components/vehicle/VehicleItem.vue"
import ImageCard from "../../components/general/ImageCard.vue"
import moment from 'moment';

export default {
    name: "TransactionForm",
    data() {
        return {
            transactionEdit: {
                amount: 1
            },
            isNewTrans: false,
            isSaving: false,
            fileUploadEvent: null,
            uploadProcessIsRunning: false
        }
    },
    created() {
        if(this.transactions.length == 0) {
            this.$store.dispatch('vehicle/getTransactions').then(() => {
                this.init();
            }) 
        } else {
            this.init();
        }
        if(this.transCategories.length == 0) {
            this.$store.dispatch('vehicle/getAllVehicleStuff').then(() => {
                this.init();
            }) 
        } else {
            this.init();
        }
        this.$store.dispatch('vehicle/changeFocusCar')
    },
    mounted() {
    },
    methods: {
        init() {
            this.transactionEdit = this.transactions.find(obj => obj.id == this.$route.params.transactionId)
            if(this.transactionEdit == undefined) {
                this.transactionEdit = {}
                this.isNewTrans = true
            } else {
                this.isNewTrans = false
            }
            let category = this.transCategories.find(obj => obj.id == this.$route.params.categoryId)
            if(category != undefined) {
                this.selectCategory(category)
            }
        },
        selectCategory(value) {
            this.transactionEdit.category = value
            if(value == null) {
                return
            }
            if(value.id != 1) {
                this.transactionEdit.amount = 1;
            }
            
        },
        focusKmInput() {
            this.$refs.kmInput.select()
        },
        save(withDocuments) {
            this.$store.dispatch('system/setIsLoading', true)
            if(this.transactionEdit.category.id === 1) {
                if(this.transactionEdit.FuelType == undefined) {
                    let flash = {
                        type: "danger",
                        aliveTime: 4500,
                        message: "Keine Kraftstoffart ausgewählt"
                    }
                    this.$store.dispatch('system/addFlashMessage', flash)
                    this.$store.dispatch('system/setIsLoading', false)
                    return
                }
            }
            this.isSaving = true;
            this.transactionEdit.vehicle = this.vehicle.id
            this.$store.dispatch('vehicle/updateTransaction', this.transactionEdit).then((response) => {

                if(withDocuments) {
                    this.goToTransaction(response.data.content.id)
                    this.isSaving = false
                    this.$store.dispatch('system/setIsLoading', false)
                } else {
                    let message = {
                        message: "Aktuelle Daten werden geladen. Du wirst gleich weitergeleitet ... ",
                        type: "info",
                        aliveTime: 3000
                    }
                    this.$store.dispatch('system/addFlashMessage', message)
                    this.$store.dispatch('vehicle/getTransactions', moment().format('YYYY')).then(() => {
                        this.$store.dispatch('system/setIsLoading', false)
                        this.isSaving = false
                        this.goToDashboard();
                       
                    }) ;
                    
                }

            }) 
        },
        goToDashboard() {
            this.$router.push({name: 'vehicleDashboard'})
        },
        goToTransaction(transactionId) {
            this.isNewTrans = false
            this.$router.push({name: 'transactionForm', params: { transactionId: transactionId }})
        },
        prepareFileUpload(event) {
            this.uploadProcessIsRunning = false;
            this.fileUploadEvent = event.target.files;
        },
        uploadFiles() {
            if(this.uploadProcessIsRunning == true) {
                return;
            } else {
                this.uploadProcessIsRunning = true;
            }
            this.$store.dispatch('vehicle/uploadTransactionImage', {
                    transaction: this.transactionEdit,
                    files: this.fileUploadEvent
                }).then(() => {
                    this.fileUploadEvent = null
                    this.$store.dispatch("vehicle/getTransactions").then(() => {
                        this.init()
                })
            })
        },
        removedFile() {
            this.$store.dispatch("vehicle/getTransactions").then(() => {
                this.init()
            })
        }
    },
    computed: {
        ...mapGetters({
            'transactions': 'vehicle/getTransactions',
            'transCategories': 'vehicle/getTransactionCategories',
            'vehicleItems': 'vehicle/getVehicleItems',
            'focusCarId': 'vehicle/getFocusCar'
        }),
        allowedToSave() {
            if(this.transactionEdit.amount <= 0 || this.transactionEdit.amount == undefined) {
                return false
            }
            if(this.transactionEdit.costs == 0 || this.transactionEdit.costs == undefined) {
                return false
            }
            if(this.transactionEdit.mileage == 0 || this.transactionEdit.mileage == undefined) {
                return false
            }
            return true
        },
        vehicle() {
            return this.vehicleItems.find(obj => obj.id == this.focusCarId);
        },
    },
    components: {
        VehicleItem,
        ImageCard
    }
}
</script>

<style>
    input[type="file"] {
            display: none;
        }

        .custom-file-upload {
            border: 1px solid #ccc;
            background-color: rgba(255,255,255,0.08);
            border-radius: 10px;
            width: 100%;
            height: 115px;
            display: inline-block;
            margin: 0px;
            cursor: pointer;
        }

    .minWidth {
        min-width: 110px;
    }
</style>