<template>
    <h3 class="text-center">Analyse</h3>
    <div v-if="vehicle">
        <VehicleItem :vehicle="vehicle" />
        <h5 @click="openEditExpected" class="text-center pointer mt-3">Deine Annahmen</h5>
        <div></div>
        <table class="table table-striped">
            <tbody>
                <tr>
                    <th  @click="openEditExpected">Kaufpreis</th>
                    <td  @click="openEditExpected" v-if="!expectedInput">
                        {{vehicle.costAsNew}} €
                    </td>
                    <td v-if="expectedInput">
                        <input v-model="vehicle.costAsNew" class="form-control"  />
                    </td>
                    <td @click="questionCostAsNew" class="pointer">
                        <i class="fa-solid fa-circle-question"></i>
                    </td>
                </tr>
                <tr>
                    <th  @click="openEditExpected">Max. Laufleistung: </th>
                    <td  @click="openEditExpected" v-if="!expectedInput">
                        {{vehicle.expectedMileage}} km
                    </td>
                    <td v-if="expectedInput">
                        <input v-model="vehicle.expectedMileage" class="form-control"  />
                    </td>
                    <td @click="questionExpectedMileage" class="pointer">
                        <i class="fa-solid fa-circle-question"></i>
                    </td>
                </tr>
                <tr>
                    <th  @click="openEditExpected">Max. Alter</th>
                    <td class="pointer" @click="openEditExpected" v-if="!expectedInput">
                        {{vehicle.expectedMaxAge}} Jahre
                    </td>
                    <td v-if="expectedInput">
                        <input class="form-control" v-model="vehicle.expectedMaxAge" />
                    </td>
                    <td @click="questionMaxOldExpected" class="pointer">
                        <i class="fa-solid fa-circle-question"></i>
                    </td>
                </tr>
            </tbody>
        </table>
        <button @click="saveVehicle" v-if="expectedInput" class="btn w-100 btn-success">Speichern</button>
        <button @click="getAnalyse" class="btn btn-info mt-1 float-end me-1"><i class="fa-solid fa-magnifying-glass-chart fa-lg"></i> Analyse ausführen</button>
        <div class="clearfix"></div>
    </div>

    <!--- Data  -->
<div v-if="!analyse.isAnalyseSuccess&&analyseIsReady" class="alert alert-danger mt-5">
    Es sind noch nicht alle Parameter bekannt, um eine Analyse zu erhalten. Oder die angegeben Werte wiedersprechen sich.
</div>

<div v-if="analyse.isAnalyseSuccess&&analyseIsReady">
    <div class="m-1">
        <div class="card bg-dark">
            <div class="card-header text-dark">
                <i class="fa-solid fa-sack-dollar"></i> Empfohlene Rücklagen für einen Neukauf
            </div>
            <div class="card-body text-center">
                {{getNiceNumber(analyse.recommendCash)}} €
            </div>
        </div>
    </div>
    <div class="m-1">
        <div class="card bg-dark">
            <div class="card-header text-dark">
                <i class="fa-solid fa-gauge"></i> Kilometerstand <span>{{analyse.mileage}} km</span>
            </div>
            <div class="card-body text-center">
                <mileage-overview :analyseItems="analyse.fuelAnalyse" />
            </div>
        </div>
    </div>
    <!--- Data  -->

    <div class="m-1">
        <div class="card bg-dark">
            <div class="card-header text-dark">
                <i class="fa-solid fa-gas-pump"></i> Spritkosten
            </div>
            <div class="card-body text-center">
                <fuel-costs :fuelCostItems="analyse.fuelAnalyse" />
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import VehicleItem from '../../components/vehicle/VehicleItem.vue';
import FuelCosts from '../../components/vehicle/charts/FuelCosts.vue';
import MileageOverview from '../../components/vehicle/charts/MileageOverview.vue';

export default {
    name: "AnalyseView",
    data() {
        return {
            expectedInput: false,
            analyseIsReady: false
        }
    },
    created() {
        this.$store.dispatch('vehicle/changeFocusCar').then(() => {
            if(this.focusCarId == null) {
                this.goToVehicleList();
            }
        }) 
    },
    methods: {
        questionCostAsNew() {
            let dialog = {
                message: "Wenn du dein Fahrzeug jetzt neu kaufen müsstest, wie viel würdest es kosten?<br><br>Du kannst mit dem Parameter auch spielen wenn du beispielsweise mit einem höherwertigen Auto liebäugelst. ;)",
                confirmText: "Verstehe"
            }
            this.$store.dispatch('system/startNewInfo', dialog)
        },
        getAnalyse() {

            this.$store.dispatch('vehicle/getAnalyse').then(() => {
                this.analyseIsReady = true;
            }) 
        },
        questionExpectedMileage() {
            let dialog = {
                message: "Was denkst du, wie viele Kilometer wird dein Fahrzeug noch durchhalten?",
                confirmText: "Verstehe"
            }
            this.$store.dispatch('system/startNewInfo', dialog)
        },
        questionMaxOldExpected() {
            let dialog = {
                message: "Wenn das Auto nur rumsteht wird es nicht besser. Was denkst du wie alt kann dein Auto werden?<br>Einflüsse haben etwa salzige Luft oder schlechter Zustand des Lacks.",
                confirmText: "Okay, ich denke drüber nach"
            }
            this.$store.dispatch('system/startNewInfo', dialog)
        },
        openEditExpected() {
            this.expectedInput = true
        },
        saveVehicle() {
            this.$store.dispatch('vehicle/saveVehicle', this.vehicle)
            this.expectedInput = false;
        },
        getNiceNumber(number, decimal = 2) {
            let convNumber = parseFloat(number)
            const myObj = {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: decimal
                }
            return convNumber.toLocaleString('de-DE',myObj);
        },
    },
    computed: {
        ...mapGetters({
            vehicleItems: 'vehicle/getVehicleItems',
            transactions: 'vehicle/getTransactions',
            focusCarId: 'vehicle/getFocusCar',
            analyse: 'vehicle/getAnalyse'
        }),
        vehicle() {
            return this.vehicleItems.find(obj => obj.id == this.focusCarId);
        },
    },

    components: {
        VehicleItem,
        FuelCosts,
        MileageOverview
    }
}
</script>

<style lang="scss" scoped>

</style>