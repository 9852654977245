const calculator = {
    init(transactions) {
        this.transactions = transactions
    },
    getCategoryCosts(categoryId) {
        let allTransactions =  this.transactions.filter(obj => obj.category.id == categoryId)
        let value = 0;
        for(let trans of allTransactions) {
            value += trans.amount * trans.costs;
        }
        return value
    }
}

export default calculator;