import axios from "axios";
import qs from 'qs'

const state = {
}


const mutations = {
}

const actions = {
    removeFile(commit, imageNumber) {
        let url = '/api/v1/file/deleteFile/'+imageNumber;
        let request = axios.delete(url);
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
        })
        return request;
    },
    updateFile(commt, image) {
        let data =  qs.stringify(image);
        let url = '/api/v1/file/updateFile/'+image.id;
        let request = axios.post(url, data);
        request.then((response) => {
            this.dispatch('system/responseProcessing', response)
        })
        return request;
    } 
}

const getters = {

}


const namespaced = true;

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced
};