<template>
<div class="fullScreenShow mb-5 fixed-top" @click="closeOverlay">
    <div class="container mb-5 mt-5" @click.stop>
        <div class="card">
            <div class="card-header text-dark">
                Tankstellen in deiner Umgebung
                <span @click="closeOverlay" class="float-end pointer">
                    <i class="fa-solid fa-xmark fa-lg"></i>
                </span>
            </div>
            <div class="card-body p-2 bg-dark">
                    <button @click="changeFuelType('E5')" class="btn ms-1" :class="getBtnStyleClass('E5')">E5</button>
                    <button @click="changeFuelType('E10')" class="btn ms-1" :class="getBtnStyleClass('E10')">E10</button>
                    <button @click="changeFuelType('Diesel')" class="btn ms-1" :class="getBtnStyleClass('Diesel')">Diesel</button>
                    <button  class="btn btn-outline-warning ms-1 float-end" @click="showInfoMessage">Andere</button>
<!--                 <button @click="changeFuelType(type.name)" :key="type.id" v-for="(type, index) of vehicle.FuelTypes" class="btn ms-1" :class="getBtnStyleClass(type.name)">
                    {{type.name}}
                </button> -->
            </div>
            <div class="card-body p-0 bg-dark">
<!--                 <table class="table table-striped" >
                    <thead>
                        <th>Tankstelle</th><th>Adresse</th><th>Entfernung</th><th>Preis ({{fuelType}})</th>
                    </thead>
                    <tbody> -->
                        <div class="card-body">
                            Umkreis: <span @click="reduceStationDistance"><i class="fa-solid fa-angle-down fa-lg"></i></span> {{stationDistanceKm}} km <span @click="riseStationDistance"><i class="fa-solid fa-angle-up fa-lg"></i></span> 
                        </div>
                        <div v-if="getStationsByRadius.length == 0" class="card mt-1  ms-0 me-0 bg-dark">
                            <div class="card-body text-center m-auto">
                                Leider keine Tankstellen in deiner Nähe gefunden. Erweitere den Umkreis.
                            </div>
                        </div>
                        <div v-if="stations.length > 0">
                        <div class="pointer" @click="getPrice" :key="station.id" v-for="(station) in getStationsByRadius">
                            <div @click="importFuelPrice(station)" class="card mt-1  ms-0 me-0 bg-dark">
                                <div class="card-header" :class="isOpenClass(station)">
                                    <div>{{station.name}}</div>
                                </div>
                                <div class="card-body">
                                    <div v-if="fuelType == 'E5'" class="text-center">
                                        <b>{{station.e5}} €</b>
                                    </div>
                                    <div v-if="fuelType == 'E10'"  class="text-center">
                                        <b>{{station.e10}} €</b>
                                    </div>
                                    <div v-if="fuelType == 'Diesel'"  class="text-center">
                                        <b>{{station.diesel}} €</b>
                                    </div>
                                    <div>
                                        <em>{{station.postCode}} {{station.place}} - {{station.street}} {{station.houseNumber}}</em>
                                    </div>
                                </div>
                                <div class="card-footer">
                                    {{station.dist}} km
                                </div>
                            </div>
                            <!-- <th>{{station.name}}</th> <td>{{station.postCode}} {{station.place}} - {{station.street}} {{station.houseNumber}}</td> <td>{{station.dist}} km</td><td>{{station.price}} €</td> -->
                        </div>
                        </div>
                        
<!--                     </tbody>
                </table> -->

            </div>
        </div>
    </div>
    <div class="m-5"></div>
</div>

</template>

<script>    
import { mapGetters } from 'vuex';

export default {
    name: "FuelStationList",
    data() {
        return {
            fuelType: "E10",
            stationDistanceKm: 4
        }
    },
    created() {
        this.getFuelStations();
    },
    mounted() {
    },
    methods: {
        getFuelStations() {
               navigator.geolocation.getCurrentPosition(
                
                    position => {
                        const lat = position.coords.latitude;
                        const long = position.coords.longitude;
                        const value = {
                            lat: lat,
                            long: long,
                            distance: 50,
                            fuelType: 'all'
                        }
                        
                        this.$store.dispatch('fuelprice/getFuelStation', value)
                    },
                    error => {
                        console.log(error.message);
                    }
                );
            },
            getStationPrice(station) {
                return station[this.fuelType.name]
            },
            isOpenClass(station) {
                if(station.isOpen == true) {
                    return 'bg-success'
                }
                return 'bg-danger'
            },
            closeOverlay() {
                this.$emit('close')
            },
            importFuelPrice(station) {
                this.$emit('importFuelStation', station, this.fuelType)
            },
            changeFuelType(fuelTypeName) {
                this.fuelType = this.FuelTypes.find(obj => obj.name.toLowerCase() == fuelTypeName.toLowerCase()).name;
            },
            getBtnStyleClass(fuelType) {
                if(fuelType === this.fuelType) {
                    return "btn-primary"
                }
               return "btn-outline-primary"
            },
            reduceStationDistance() {
                this.stationDistanceKm=this.stationDistanceKm-1;
            },
            riseStationDistance() {
                this.stationDistanceKm=this.stationDistanceKm+1;
            },
            showInfoMessage() {
                let message = {
                    message: "Tankstellen müssen die aktuellen Spritpreise zeitnah an die Markttransparenzstelle für Krafstoffe (MTS-K) weiterleitern.<br><br>Leider bezieht sich das derzeit nur auf Super E5, Super E10 und Diesel.<br><br>Andere Kraftstoffarten werden derzeit nicht übermittelt.<br>Sobald sich dieses ändert werden weitere Kraftstoffarten nachgeflegt.<br><br>Solange musst du die Preise händig eingeben.",
                    confirmText: "Schade :("
                }
                this.$store.dispatch('system/startNewInfo', message)
            }
    },
    computed: {
        ...mapGetters({
            stations: 'fuelprice/getStations',
            FuelTypes: 'vehicle/getFuelTypes'

        }),
        getStationsByRadius() {
            return this.stations.filter(obj => obj.dist <= this.stationDistanceKm)
        }
       
    },
    props: {
        vehicle: Object
/*         fuelType: {
            type: String,
            default: 'e10'
        } */
    },

}
</script>

<style lang="scss" scoped>
    .fullScreenShow {
        height: 100%;
        width: 100%;
        background-color: rgba(0,0,0,0.8);
        overflow-y:scroll;
        overflow-x:hidden;
    }

    .imageWindow {
        max-width: 400px;
        max-height: 800px;
    }
</style>