<template>
    <header-image :headerText="'Garage'" :imageLink="require('../../files/vehicle/garage.jpg')" />

<!--     <div class="d-flex justify-content-end mt-2">
        <button @click="showNewVehicleModal=true" class="txBtn txBtn-primary">Fahrzeug Hinzufügen</button>
    </div> -->


    <div class="border-top border-bottom p-1 border-primary mt-3">
        <div class="row mt-2">
            <div class="col-12 col-md-6 col-lg-4 mt-2" :key="vehicle.id" v-for="(vehicle) of vehicleItems">
                <VehicleItem :vehicle="vehicle"/>
            </div>
            <div class="col-12 col-md-6 col-lg-4 mt-2">
                    <div class="card h-100 bg-dark pointer">
                        <div @click="showNewVehicleModal=true" class="card-body d-flex justify-content-center align-items-center">
                            <span><i class="fa-solid fa-plus fa-3x"></i>  <i class="fa-solid fa-car fa-3x"></i></span>
                        </div>
                    </div>
            </div>
        </div>
    </div>

    <NewVehicleModal v-show="showNewVehicleModal" @setDisable="showNewVehicleModal=false" />

</template>

<script>
import { mapGetters } from 'vuex'
import HeaderImage from '../../components/general/HeaderImage.vue'
import VehicleItem from '../../components/vehicle/VehicleItem.vue';
import NewVehicleModal from '../../components/vehicle/NewVehicle.vue';

export default {
    data() {
        return {
            showNewVehicleModal: false
        };
    },
    created() {
        this.$store.dispatch("vehicle/getVehicleItems");
    },
    methods: {},
    computed: {
        ...mapGetters({
            user: "user/getUser",
            vehicleItems: 'vehicle/getVehicleItems'
        })
    },
    components: { VehicleItem, NewVehicleModal, HeaderImage }
}
</script>

<style lang="scss" scoped>
</style>