<template>
    <div class="card bg-dark mb-1 ">
        <div  @click="toggleEdit" class="d-flex justify-content-between" :class="cardHeaderStyle" data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id" aria-expanded="false" :aria-controls="'collapseEditTransaction'+transaction.id">
                <div class="me-1">
                    <b><span><i :class="getCategoryTransactionIcon"></i></span> {{getCategoryTransactionName}} </b>
                </div>

                <div class="">
                   <b>{{transaction.mileage}} km</b>
                </div>
        </div>
        <div v-if="transaction.name" class="card-footer" @click="toggleEdit" data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id" aria-expanded="false" :aria-controls="'collapseEditTransaction'+transaction.id">
            <div class=" me-1">
                {{transaction.name}}
            </div>
        </div>
        <div v-if="!showEdit" >
            <div class="card-body d-flex justify-content-between">
                <div @click="openEdit" class="formWidth row" data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id" aria-expanded="false" :aria-controls="'collapseEditTransaction'+transaction.id">
                    <div class="col-6">
                        {{createDate}}
                    </div>
                    <div class="col">
                        {{getNiceNumber(transaction.amount, 2)}} x
                        {{getNiceNumber(transaction.costs, 3)}} €
                    </div>
                </div>
                <div class="d-none d-lg-block mt-auto mb-auto">
                    <template v-if="!showEdit" >
                        <span @click="openEdit" class="text-white pointer me-2" data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id" aria-expanded="false" :aria-controls="'collapseEditTransaction'+transaction.id">
                            <i class="fa-solid fa-edit fa-lg"></i>
                        </span>
                        <span @click="deleteTransaction" class="text-danger pointer">
                            <i class="fa-solid fa-trash fa-lg"></i>
                        </span>
                    </template>
                </div>
            </div>
        </div>
        <div class="row p-2 collapse"  :id="'collapseEditTransaction'+transaction.id">
            <div class="actionBar">
                <div class="float-start">
                    <span @click="deleteTransaction" class="txBtn txBtn-danger me-2"  data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id">
                        Löschen
                    </span>
                </div>
                <div class="float-end"> 
                    <span @click="closeEdit" class="txBtn txBtn-light me-2"  data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id" >
                        Abbrechen
                    </span>   
                    <span @click="save" class="txBtn txBtn-success"  data-bs-toggle="collapse" :data-bs-target="'#collapseEditTransaction'+transaction.id">
                        Speichern
                    </span>   
                </div>
            </div>
            <div @keydown.enter="save" @keydown.esc="closeEdit" v-if="showEdit" class="col-12 col-md-6">
                <div class="mb-2 ">
                    <label for="dateCreated" class="form-label">Datum</label>
                    <input v-model="editTransaction.created" type="datetime-local" class="form-control" id="dateCreated" placeholder="Datum">
                </div>
                <div class="mb-2 ">
                    <label for="transactionName" class="form-label">Bezeichnung</label>
                    <input v-model="editTransaction.name" type="text" class="form-control" id="transactionName" placeholder="Für was war die Transaktion?">
                </div>
                <div class="mb-2 ">
                    <label for="mileage" class="form-label">Kilometerstand</label>
                    <input v-model="editTransaction.mileage" type="text" class="form-control" id="mileage" placeholder="Kilometerstand">
                </div>
                <div class="mb-2">
                    <label for="amount" class="form-label"><span><i :class="whishCategory.icon"></i> Kategorie<span  v-if="editTransaction.category.id === 1" > / Kraftstoff</span></span></label>
                    <div class="input-group">
                        <vehicle-category-dropdown :selectedCategory="editTransaction.category" @changeCategory="changeCategory" />
                        <select v-if="editTransaction.category.id === 1"  class="form-select" v-model="editTransaction.FuelType">
                            <option :key="type.id" :value="type" v-for="(type) of vehicle.FuelTypes">
                                {{type.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="mb-2">
                    <label for="amount" class="form-label">{{whishCategory.unit}}</label>
                    <input v-model="editTransaction.amount" type="number" class="form-control" id="amount" placeholder="Anzahl">
                </div>
                <div class="mb-2">
                    <label for="costs" class="form-label">Kosten / {{whishCategory.unit}}</label>
                    <input v-model="editTransaction.costs" type="number" class="form-control" id="costs" placeholder="Kosten in €">
                </div>
            </div>
            <div class="col-12 col-md-6">
                <div class="mb-2 ">
                    <label for="dateCreated" class="form-label">Beschreibung & Details</label>
                    <div>          
                        <textarea v-model="editTransaction.description" :v-html="editTransaction.description" class="rounded bg-dark text-white w-100">
                        </textarea>
                    </div>
                </div>
                <div class="mb-2 ">
                    <label for="dateCreated" class="form-label">Bilder & Dateien</label>
                    <div>          
                        <label class="custom-file-upload text-center">
                        <input ref="file" type="file" multiple  @change="prepareFileUpload( $event )" />
                            <div class="m-4">
                                <div  v-if="!fileUploadEvent" class="inline-block align-middle">
                                <i class="fa-solid fa-images fa-2x"></i> 
                                    <div>
                                        Ziehe Fotos in das Feld oder wähle welche aus.
                                    </div>
                                </div>
                                <div class="m-4" v-if="fileUploadEvent" >
                                    <div class="mb-2">
                                    <span  v-if="fileUploadEvent">{{fileUploadEvent.length}}x Dateien oder Bilder</span>
                                    </div>
                                    <button  v-if="!uploadProcessIsRunning" @click="uploadFiles" class="btn btn-primary">Hochladen</button>
                                </div>
                            </div>
                        </label>

                    </div>
                </div>
                <div class="row">
                    <div class="col-6 col-md-4" :key="image.id" v-for="image in editTransaction.allImages">
                        <image-card :image="image" @removedFile="removedFile" />
                    </div>
                </div>
            </div>
        </div>
        <div class="card-footer">
                <div v-if="this.transaction.updated" class="float-start text-muted text-sm ">
                    <em class="m-auto">geändert am: {{updateDate}}</em>
                </div>
                <div class="float-end col">
                    <b>{{getNiceNumber(transaction.costs * transaction.amount,2)}} €</b>
                </div>
        </div>
    </div>
</template>

<script>
import helper from '../../js/helper'
import VehicleCategoryDropdown from './VehicleCategoryDropdown.vue'
import ImageCard from './../general/ImageCard.vue'
import moment from 'moment'

export default {
    name: "VehicleTransactionCard",
    data() {
        return {
            showEdit: false,
            editTransaction: null,
            whishCategory: null,
            fileUploadEvent: null,
            uploadProcessIsRunning: false
        }
    },
    created() {
        moment.locale('de');
        this.init()
    },
    mounted() {
        if(this.transaction.costs == 0) {
            this.openEdit()
        }
    },
    methods: {
        init() {
            this.editTransaction = helper.objectCopy(this.transaction);
            this.whishCategory = this.editTransaction.category;
        },
        deleteTransaction() {
            const dialog = {
                message: 'Bist du sicher, dass du die Transaktion "' + this.transaction.name + '" löschen möchtest?',
                confirmText: 'Ja bitte löschen',
                cancelText: "Abbruch"
            }
            dialog.confirmFunction = () => {
                this.$store.dispatch('vehicle/removeTransaction', this.transaction ).then((response) => {
                    this.$store.dispatch("vehicle/getTransactions").then(() => {
                        this.init()
                    })
                    if(response.status == 201) {
                     // 
                    }
                })
            }
            this.$store.dispatch('system/startNewConfirm', dialog)
        },
        toggleEdit() {
            if(this.showEdit) {
                this.closeEdit();
            } else {
                this.openEdit();
            }
        },
        openEdit() {
            this.init()
            this.showEdit = true;
        },
        closeEdit() {
            this.showEdit = false;
        },
        save() {
            if(this.whishCategory.id === 1) {
                if(this.editTransaction.FuelType == undefined) {
                    let flash = {
                        type: "danger",
                        aliveTime: 4500,
                        message: "Keine Kraftstoffart ausgewählt"
                    }
                    this.$store.dispatch('system/addFlashMessage', flash)
                    return
                }
            }
            this.showEdit = false
            this.$store.dispatch('vehicle/updateTransaction', this.editTransaction ).then(() => {
                this.editTransaction = helper.objectCopy(this.transaction);
            })
        },
        getNiceNumber(number, decimal = 2) {
            let convNumber = parseFloat(number)
            const myObj = {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: decimal
                }
            return convNumber.toLocaleString('de-DE',myObj);
        },
        changeCategory(category) {
            this.whishCategory = category;
        },
        prepareFileUpload(event) {
            this.uploadProcessIsRunning = false;
            this.fileUploadEvent = event.target.files;
        },
        uploadFiles() {
            if(this.uploadProcessIsRunning == true) {
                return;
            } else {
                this.uploadProcessIsRunning = true;
            }
            this.$store.dispatch('vehicle/uploadTransactionImage', {
                    transaction: this.transaction,
                    files: this.fileUploadEvent
                }).then(() => {
                    this.fileUploadEvent = null
                    this.$store.dispatch("vehicle/getTransactions").then(() => {
                        this.init()
                })
            })
        },
        removedFile() {
            this.$store.dispatch("vehicle/getTransactions").then(() => {
                this.init()
            })
        }
    },
    computed: {
            createDate() {
                return moment(this.transaction.created).format('L')
            },
            updateDate() {
                return moment(this.transaction.updated).format('L') + " um " + moment(this.transaction.updated).format('LT') + " Uhr"
            },
            getCategoryTransactionName() {
                if(this.transaction === undefined) return ""  
                if(this.transaction.category == undefined) {
                    return "";
                }
                return this.transaction.category.name
            },
            getCategoryTransactionIcon() {
                if(this.transaction === undefined) return ""  
                if(this.transaction.category == undefined) {
                    return "";
                }
                return this.transaction.category.faIcon
            },
            cardHeaderStyle() {
                if(this.isNewCard) {
                    return 'card-header bg-primary text-dark'
                } else {
                    return 'card-footer text-light'
                }
            }
    },
    props: {
        transaction: Object,
        vehicle: Object,
        isNewCard: {
            type: Boolean,
            default: true
        }
    },
    components: {
        ImageCard, 
        VehicleCategoryDropdown
    }
}
</script>

<style lang="scss" scoped>
    .formWidth {
        width: 100%;
        @media (min-width: 768px) { 
            width: 50%;
         }
    }
    .text-sm {
        font-size: 0.8em;
    }

    input[type="file"] {
            display: none;
        }

        .custom-file-upload {
            border: 1px solid #ccc;
            background-color: rgba(255,255,255,0.08);
            border-radius: 10px;
            width: 100%;
            height: 115px;
            display: inline-block;
            margin: 0px;

            cursor: pointer;
        }
</style>