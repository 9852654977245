<template>
    <h1 @click="updateData" >HomeView</h1>
    <span>Hallo {{user.name}},</span>
    <div class="row ms-1 me-1 mt-4 text-center">
        <div class="col-6 p-1">
            <div @click="goTo('changeLog')" class="card p-0 border-dark pointer">
                <div class="card-header bg-dark">Changelog</div>
                <div class="card-image">
                    <img  class="img-fluid cardHeight" src="../files/system/update.jpg" />
                </div>
            </div>
        </div>
        <div class="col-6 p-1">
            <div @click="goTo('settings')" class="card p-0 border-dark pointer">
                <div class="card-header bg-dark">Einstellung</div>
                <div class="card-image">
                    <img  class="img-fluid cardHeight" src="../files/system/mixer.jpg" />
                </div>
            </div>
        </div>
    </div>
    
    <button @click="updateData" class="btn btn-outline-primary mt-5">Daten erneut laden</button>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    created() {

    },
    computed: {
        ...mapGetters({
            user: 'user/getUser',
            transactions: 'vehicle/getTransactions'
        })
    },
    methods: {
        updateData() {
            this.$store.dispatch('system/startLoadingProcess')
        },
        goTo(name) {
            this.$router.push({name: name})
        }
    }
}
</script>