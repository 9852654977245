<template>
    <div class="fixed-bottom mb-5">
        <div class="ms-4 me-4" :key="message.id" v-for="(message) of flashMessages">
            <FlashMessage :element="message" @remove="removeItem" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import FlashMessage from './FlashMessage.vue';

export default {
    data() {
        return {

        }
    },
    computed: {
        ...mapGetters({
            flashMessages: 'system/getFlashMessages'
        })
    },
    methods: {
        removeItem(item) {
            this.$store.dispatch('system/removeFlashMessage', item)
        }
    },
    components: {
        FlashMessage
    }

}
</script>