<template>
    <h1>Fahrzeug Bearbeiten</h1>
    <template v-if="editVehicle">
        <button @click="goToVehicleList" class="d-none d-md-inline txBtn txBtn-primary">Zur Fahrzeugliste</button>
        <div @keydown.enter="save" class="formStyle">
            <div class="mb-2">
                <label for="name" class="form-label">Name</label>
                <input v-model="editVehicle.name" class="form-control" type="text" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Kennzeichen</label>
                <input v-model='editVehicle.licensePlate' class="form-control" type="text" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Kaufdatum</label>
                <input v-model='editVehicle.buyDate' class="form-control" type="date" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Baujahr / Datum</label>
                <input v-model='editVehicle.constructionYear' class="form-control" type="date" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Hersteller</label>
                <input v-model='editVehicle.manufacturer' class="form-control" type="text" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Modell</label>
                <input v-model='editVehicle.typ' class="form-control" type="text" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Treibstoffarten</label>
                <select v-if="editVehicle.FuelTypes" multiple v-model="editVehicle.FuelTypes" @change="changeCategory" class="form-select">
                    <option  :key="type.id" :value="type" v-for="(type) in fuelTypes">
                        {{type.name}}
                    </option>
                </select>
            </div>
            <div class="mb-2">
                <label for="firstname" class="form-label">Neupreis</label>
                <input v-model='editVehicle.costAsNew' class="form-control" type="number" />
                <!--<div id="emailHelp" class="form-text">We'll never share your email with anyone else.</div> -->
            </div>
            <div class="mb-2">
            <label for="dateCreated" class="form-label">Bilder & Dateien</label>
                <div>          
                <label class="custom-file-upload text-center">
                <input ref="file" type="file" multiple  @change="prepareFileUpload( $event )" />
                    <div class="m-4">
                        <div  v-if="!fileUploadEvent" class="inline-block align-middle">
                        <i class="fa-solid fa-images fa-2x"></i> 
                            <div>
                                Ziehe Fotos in das Feld oder wähle welche aus.
                            </div>
                        </div>
                        <div class="m-4" v-if="fileUploadEvent" >
                            <div class="">
                            <span  v-if="fileUploadEvent">{{fileUploadEvent.length}}x Dateien oder Bilder</span>
                            </div>
                            
                        </div>
                    </div>
                </label>
                <button v-if="!uploadProcessIsRunning&&fileUploadEvent" @click="uploadFiles" class="btn btn-primary mt-1 float-end">Hochladen</button>
            </div>
        </div>
        <button @click="goToVehicleList" for="cancel" class="form-control mt-5 btn btn-light"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Abbrechen</button>
        <button @click="save(false)" for="save" class="form-control mt-1 btn btn-success"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern</button>
        <button @click="save(true)" for="save" class="form-control mt-1 btn btn-success"><span v-show="isSaving" class="spinner-border spinner-border-sm text-white"></span> Speichern & zum Dashboard</button>
        </div>
        <hr>


        <h5 class="text-center">Hauptbild des Fahrzeugs</h5>
        <div v-if="!editVehicle.mainPicture" class="alert alert-warning mt-3">
            Dein Fahrzeug hat noch kein Hauptbild. Für eine bessere Übersichtlichkeit wird empfohlen ein Bild zu verwenden.<br>Lade ein Bild hoch und klicke anschließend auf <i class="fa-solid fa-arrows-to-eye"></i>.
        </div>
        <div v-if="editVehicle.mainPicture">
            <div v-if="editVehicle.mainPicture.size > 500000" class="alert alert-warning mt-3">
                Das Bild ist mit {{getNiceNumber(editVehicle.mainPicture.size/1000)}} kByte etwas groß und kann dadurch zu einem höheren Datenverbrauch und längeren Ladezeiten führen.<br>In Zukunft wird das System die Bildgröße automatisch händeln oder dir eine Möglichkeit geben es hier zu verkleinern.
            </div>
        </div>

        <div class="d-flex justify-content-center">

            <div v-if="editVehicle.mainPicture" class="card bg-dark maxWidth">
                <image-card :image="editVehicle.mainPicture" 
                                :isMainable="editVehicle.mainPicture.extension.isImage" 
                                :mainPicture="editVehicle.mainPicture"
                                @removedFile="reReadVehicles"
                                @setMainImage="setMainImage"
                                @unlinkMainImage="unlinkMainImage" />
                <div class="card-footer">
                    <button @click="unlinkMainImage" class="btn btn-outline-warning">Dieses Bild nicht mehr als Hauptbild verwenden</button>
                </div>
            </div>
        </div>
        <hr>
        <h5 class="text-center">Alle Bilder</h5>
        <div v-if="showImages">
            <div class="mt-3 row">
                <template  :key="image.id" v-for="image in editVehicle.allImages">
                    <div class="col-6 col-md-4 mt-1">
                        <image-card :image="image" 
                            :isMainable="image.extension.isImage" 
                            :mainPicture="editVehicle.mainPicture"
                            @removedFile="reReadVehicles"
                            @setMainImage="setMainImage"
                            @unlinkMainImage="unlinkMainImage" />
                    </div>
                </template>
            </div>
        </div>
    </template>
</template>

<script>
import { mapGetters } from 'vuex';
import ImageCard from './../../components/general/ImageCard.vue'
const ReadText = require('text-from-image');

export default {
    name: "VehicleEdit",
    data() {
        return {
            isSaving: false,
            fileUploadEvent: null,
            uploadProcessIsRunning: false,
            showImages: true
        };
    },
    mounted() {
        this.init();
    },
    computed: {
        ...mapGetters({
            vehicleItems: 'vehicle/getVehicleItems',
            fuelTypes: 'vehicle/getFuelTypes'
        }),
        editVehicle() {
            return this.vehicleItems.find(obj => obj.id == this.$route.params.vehicleId);
        },

    },
    methods: {
        init() {
/*         this.editVehicle = this.vehicleItems.find(obj => obj.id == this.$route.params.vehicleId);
        this.$store.dispatch("vehicle/getVehicleItems").then(() => {
            this.editVehicle = this.vehicleItems.find(obj => obj.id == this.$route.params.vehicleId);
        }) */
        },
        save(goToDashboard) {
            this.isSaving = true
            this.$store.dispatch('vehicle/saveVehicle', this.editVehicle).then(() => {
                if(goToDashboard) {
                    this.$router.push({name: 'vehicleDashboard'})
                }
                this.isSaving = false;
            })
        },
        goToVehicleList() {
            this.$router.push({name: 'vehicleList'});
        },
        prepareFileUpload(event) {
            this.uploadProcessIsRunning = false;
            this.fileUploadEvent = event.target.files;
        },
        uploadFiles() {
            if(this.uploadProcessIsRunning == true) {
                return;
            } else {
                this.uploadProcessIsRunning = true;
            }
            this.$store.dispatch('vehicle/uploadVehicleImage', {
                vehicle: this.editVehicle,
                files: this.fileUploadEvent
            }).then(() => {
                this.fileUploadEvent = null
                this.$store.dispatch("vehicle/getVehicleItems").then(() => {
                this.editVehicle = this.vehicleItems.find(obj => obj.id == this.$route.params.vehicleId);
            })
            })
        },
        getNiceNumber(number, decimal = 2) {
            let convNumber = parseFloat(number)
            const myObj = {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: decimal
                }
            return convNumber.toLocaleString('de-DE',myObj);
        },
        seeText(path) {
            ReadText(path).then((text) => {
                    console.log(text)
                }).catch((err) => {
                    console.log(err)
                })
        },
        reReadVehicles() {
            this.$store.dispatch('vehicle/getVehicleItems').then(() =>  {
                this.$forceUpdate();
                this.showImages = false
                this.$nextTick(() => {
                    this.showImages = true
                })
            })
        },
        setMainImage(image) {
            let mainImage = {
                image: image,
                vehicle: this.editVehicle
            }
            
            this.$store.dispatch('vehicle/setMainImage', mainImage).then(() => {
                this.reReadVehicles();
            }) 
        },
        unlinkMainImage() {
            let mainImage = {
                image: null,
                vehicle: this.editVehicle
            }
            this.$store.dispatch('vehicle/setMainImage', mainImage).then(() => {
                this.reReadVehicles();
            }) 
        }
    },
    components: {
        ImageCard
    }
}
</script>


<style lang="scss" scoped>
     input[type="file"] {
            display: none;
        }

        .custom-file-upload {
            border: 1px solid #ccc;
            background-color: rgba(255,255,255,0.08);
            border-radius: 10px;
            width: 100%;
            height: 115px;
            display: inline-block;
            margin: 0px;

            cursor: pointer;
        }

    .maxWidth {
        max-width: 450px;
    }
</style>