<template>
     <div class="fixed-bottom navFooter d-flex justify-content-between">
        <div  @click="gotTo('home')" class="customWidth m-auto">
            <i class="fa-solid fa-house fa-lg"></i>
        </div>
        <div @click="gotTo('profile')" class="customWidth m-auto">
            <i class="fa-solid fa-user fa-lg"></i>
        </div>
        <div @click="gotTo('vehicleList')" class="customWidth m-auto">
            <i class="fa-solid fa-warehouse fa-lg"></i>
        </div>
        <div @click="gotTo('vehicleDashboard')" class="customWidth m-auto">
            <i class="fa-solid fa-car fa-lg"></i>
        </div>

     </div>
</template>

<script>
export default {
    setup() {
        
    },
    methods: {
        gotTo(routeName) {
            this.$router.push({name: routeName})
        }
    }
}
</script>

<style lang="scss">
    .customWidth {
        width: 20%;
    }
</style>